import { useEffect, useState } from "react";
import SettingsItemList from "./settingsItemList";
import { LookupDto } from "../../models/lookupDto";
import agent from "../../api/agent";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { Icon } from "semantic-ui-react";

export default function ManageDonors() {
  const { t } = useTranslation("common");
  const [loading, setLoading] = useState<boolean>(false);
  const [items, setItems] = useState<LookupDto[] | null>(null);

  const handleError = () => toast.error(t("Common.GeneralErrorMessage"));

  useEffect(() => {
    loadItems();
  }, []);

  const loadItems = () => {
    setLoading(true);
    agent.Lookups.donors()
      .then((result) => setItems(result))
      .catch(() => handleError())
      .finally(() => setLoading(false));
  };

  const deleteItem = (item: LookupDto) => {
    agent.Lookups.deleteDonor(item)
      .then(() => loadItems())
      .catch((error) => {
        if (error.response.status === 409) toast.error(t("Settings.ItemAlreadyInUseErrorMessage"));
        else handleError();
      });
  };

  const addItem = (item: LookupDto) => {
    agent.Lookups.addDonor(item)
      .then(() => loadItems())
      .catch((error) => {
        if (error.response.status === 409) toast.error(t("Common.DuplicateNameErrorMessage"));
        else handleError();
      });
  };

  const updateItem = (item: LookupDto) => {
    agent.Lookups.updateDonor(item)
      .then(() => loadItems())
      .catch((error) => {
        if (error.response.status === 409) toast.error(t("Common.DuplicateNameErrorMessage"));
        else handleError();
      });
  };

  return (
    <>
      <nav className="injaz-breadcrumbs">
        <a href="/" className="injaz-breadcrumbs__item">
          <Icon name="home" />
        </a>
        <a href="#checkout" className="injaz-breadcrumbs__item is-active">
          <span>{t("Settings.ManageDonors")}</span>
        </a>
      </nav>

      <h2>{t("Settings.ManageDonors")}</h2>

      <SettingsItemList
        items={items}
        onItemSelected={() => {}}
        onItemAdded={(i) => addItem(i)}
        onItemDeleted={(i) => deleteItem(i)}
        onItemUpdated={(i) => updateItem(i)}
        loading={loading}
      ></SettingsItemList>
    </>
  );

  return <></>;
}
